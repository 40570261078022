import {
  ref, getCurrentInstance,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function newUser() {
  const { proxy } = getCurrentInstance()
  const roleList = ref([])
  const addUserLoading = ref(false)
  const getRoleList = async () => {
    const params = {
      page_size: 99999, page_no: 1,
    }
    proxy.addUserLoading = true
    const res = await proxy.$api.fetchRoleList(params)
    proxy.addUserLoading = false
    const { code, data } = res.data
    if (code === 200) {
      const { list } = data
      roleList.value = list.map(value => ({ role_id: value.role_id, role_name: value.role_name }))
    }
  }
  const newUserTitle = ref('')
  const userNameModel = ref('')
  const emailModel = ref('')
  const roleModel = ref(null)
  const detailsModel = ref('')
  const statusModel = ref(false)
  const currentUserId = ref(null)
  const resetModal = () => {
    userNameModel.value = ''
    emailModel.value = ''
    roleModel.value = null
    detailsModel.value = ''
    statusModel.value = false
  }
  const handleSubmit = callback => {
    proxy.$refs.addUserForm.validate().then(async success => {
      if (success) {
        const params = {
          user_id: '',
          account_meta: {
            role_meta: {
              role_id: roleModel.value.role_id,
              role_name: roleModel.value.role_name,
            },
            user_name: userNameModel.value,
            email: emailModel.value,
            user_status: statusModel.value ? 1 : 2, // 1 启用 2 禁用
            description: detailsModel.value,
          },
        }
        if (currentUserId.value) {
          params.user_id = currentUserId.value
        }
        proxy.$showLoading()
        const res = await proxy.$api.saveAccount(params).catch(() => {
          proxy.$hideLoading()
        })
        proxy.$hideLoading()
        const { code, data, message } = res.data
        if (code === 200) {
          currentUserId.value = null
          proxy.$refs.newUserModel.hide()
          proxy.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Success',
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
          callback({ userId: params.user_id, userName: userNameModel.value, password: data.password })
        } else {
          proxy.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: `${message}`,
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
        }
      }
    })
  }

  return {
    newUserTitle,
    roleList,
    addUserLoading,
    userNameModel,
    emailModel,
    roleModel,
    detailsModel,
    statusModel,
    currentUserId,
    resetModal,
    getRoleList,
    handleSubmit,
  }
}
